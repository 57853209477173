.triangle {
  justify-content: flex-end;
  align-items: flex-end;
  width: 0;
  height: 0;
  border-top: 550px solid transparent;
  border-right: 1500px solid #ebfafa;
  display: flex;
}

.triangle-wrapper {
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
}

.front-cover {
  background-color: #013d5b;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-bottom: 4px solid #d7b654;
  display: flex;
}

.crest-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.crest {
  width: 50%;
}

.title {
  position: absolute;
  text-align: center;
  color: #fff;
  width: 100%;
  margin-top: 10%;
  font-size: 3vw;
  font-weight: bold;
}

.logo {
  position: absolute;
  bottom: 10px;
  right: 5px;
  width: 40%;
  z-index: 1;
}

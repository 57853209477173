body {
  background-color: #f8f8f8;
  overflow-y: hidden;
  overflow-x: hidden;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 5%;
}

.flipbook-container {
  width: 60vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(47, 95, 104, 0.445); */
  color: black;
  padding: 10px;
  margin: 10px;
  object-fit: contain;
}

@media screen and (max-width: 760px) {
  .flipbook-container {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
  /* .stf__parent {
    max-width: 100vw !important;
    min-width: 70vw !important;
    width: 100vw !important;
    max-height: 100vh !important;
    min-height: 100vh !important;
    height: 100vh !important;
    margin-top: 35px;
  }
  .stf__item {
    width: 100vw !important;
    height: calc(100vw * 1.4142) !important;
  } */
}

.footer {
  background-color: #000000;
  height: 50px;
  /* opacity: 0.55; */
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 10px;
}

.footer svg {
  fill: #fff;
  cursor: pointer;
}

.footer-item {
  margin: 10px;
}

.page-number-input {
  display: flex;
  align-items: center;
}
.page-number-input label {
  font-family: Arial, sans-serif;
  margin: 0 6px 0 6px;
  line-height: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #5f6f7b;
  -webkit-font-smoothing: antialiased;
  width: auto;
}

.page-number-input input {
  background: #141f28;
  width: 40px;
  height: 20px;
  border: 1px solid #1e2f3c;
  box-shadow: inset 0 0 3px #0a1014;
  text-align: center;
  font-size: 12px;
  color: #bababa;
}

.page-number-input button {
  box-shadow: none;
  text-shadow: none;
  font-family: Arial;
  right: 0px;
  top: 11px;
  height: 17px;
  font-size: 9px;
  font-weight: bold;
  padding: 0 1px;
  background: #a1a5a8;
}

.draggable {
  cursor: grab;
}

.dragging div {
  cursor: grabbing;
}

.flipbook-nav-container {
  display: flex;
  align-items: center;
}

.footer svg:hover {
  transform: scale(1.4);
}

.next-page-button:hover {
  cursor: pointer;
}

.next-page-button-disabled {
  color: "gray";
}

.button_lang {
  position: absolute !important;
  top: 15px;
  right: 15px;
  background-color: #013d5b !important;
  z-index: 100;
}

.mobileLogo{
  position: absolute;
  top: 15px;
  left: 15px;
  width: 40vw;
  height: auto;
}

.mobileBottomLogo{
  position: absolute;
  bottom: 17px;
  left: 30vw;
  width: 40vw;
  height: auto;
}

.logoMobileBox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 7vh;
  background-color: #1B1839;
}
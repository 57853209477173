.iframeWrapper {
  position: absolute;
  width: 90%;
  height: 50%;
  bottom: 12%;
  left: 5%;
}

.buttonWrapper {
  position: absolute;
  width: 90%;
  height: 50%;
  bottom: 15%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: #013d5b !important;
}

.socialWrapperOuter {
  position: absolute;
  width: 80%;
  max-width: 80%;
  height: 15px;
  margin: 0 10%;
  top: 39%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.socialWrapperInner {
  width: auto;
  display: flex; 
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;

}

.socialWrapper .MuiIconButton-root{
  padding: 5px !important;
}

.socialLink {
  display: flex;
}

.socialIcon {
  font-size: 13px !important;
  margin-right: 5px;
}

.socialWrapperOuter .MuiTypography-body1 {
  font-size: 10px;
}

.iframeWrapper_video {
  position: absolute;
  width: 90%;
  height: 30%;
  bottom: 6%;
  left: 5%;
}

.iframe {
  width: 200%;
  height: 200%;
  border: none;
  border-radius: 10px;

  -ms-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

@media screen and (max-width: 970px) {
  .iframe {
    width: 250%;
    height: 250%;
    border: none;
    border-radius: 10px;

    -ms-transform: scale(0.4);
    -moz-transform: scale(0.4);
    -o-transform: scale(0.4);
    -webkit-transform: scale(0.4);
    transform: scale(0.4);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 760px) {
  .iframe {
    width: 400%;
    height: 400%;
    border: none;
    border-radius: 10px;

    -ms-transform: scale(0.25);
    -moz-transform: scale(0.25);
    -o-transform: scale(0.25);
    -webkit-transform: scale(0.25);
    transform: scale(0.25);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}

.video {
  position: absolute;

  width: 90%;
  height: 50%;
  bottom: 15%;
  left: 5%;
}

.page-container {
  overflow-y: hidden;
  max-width: 100%;
  max-height: 100%;
}
.page-container::-webkit-scrollbar {
  display: none;
}
.page .page-container > img {
  max-width: 100%;
  max-height: 100%;
}

.pageImage {
  width: 100%;
  height: calc(100% * 1.4142)
}

